import React, { Component } from 'react';
import './TutorialWindow.css';
import firebase from './config/firebaseInit';

class ProjectWindow extends Component {
  constructor(props) {
    super(props);

    this.state = {currImageNum: '1'};

    this.viewRepo = this.viewRepo.bind(this);
  }

  viewRepo(){
    firebase.analytics().logEvent("tutorial_"+ this.props.repoName);
  }

  render() {
    const firstImage = require("./images/Tutorials/"+this.props.repoName+"/1.jpg");
    const repoLink = "https://github.com/Brenocq/"+this.props.repoName;

    const topics = this.props.topics;
    const listTopics = [];

    for (const [index, value] of topics.entries()) {
      if(index%10==0)
      listTopics.push(<br/>);
      listTopics.push(<span className="greyTag">&nbsp; {value} &nbsp;</span> );
    }

    return (
      <a href={repoLink} className="tutorialWindow" onClick={this.viewRepo} target="_blank">
        <img id={this.props.repoName} className="tutorialImage" src={firstImage} />
        <div className="tutorialWindowBody">
          <h3 className="tutorialTitle">{this.props.title}</h3>
          <p>
            {this.props.description}
          </p>
          <div className="featTutorial">
            <div className="topicsContainer">
              {listTopics}
            </div>
          </div>
        </div>
      </a>
    );
  }
}

export default ProjectWindow;
