import React, { Component } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import './Footer.css';


class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="socialIcons">
          <ul>
            <li><a href="https://github.com/Brenocq" target="blank"><i className="fa fa-github"/></a></li>
            <li><a href="https://www.linkedin.com/in/brenocqueiroz/" target="blank"><i className="fa fa-linkedin"/></a></li>
            <li><a href="https://www.youtube.com/channel/UCZlfooaGv_TPEHVJD-g_UUQ/" target="blank"><i className="fa fa-youtube"/></a></li>
            <li><a href="mailto:breno1423@gmail.com"><i className="fa fa-envelope"/></a></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Footer;
