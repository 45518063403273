import React, { Component } from 'react';
import './ProjectWindow.css';
import firebase from './config/firebaseInit';

function nextImage(button,projectName,imageNum,maxsize){
	let object = document.getElementById(projectName);

	if(button==="prev"){
		imageNum==1 ? imageNum=maxsize : imageNum--;
	}else{
		imageNum==maxsize ? imageNum=1 : imageNum++;
	}

	object.src = require("./images/Projects/"+projectName+"/"+imageNum+".jpg");
	return imageNum;
}


class ProjectWindow extends Component {
	constructor(props) {
		super(props);

		this.state = {currImageNum: '1'};

		this.handleClickPrev = this.handleClickPrev.bind(this);
		this.handleClickNext = this.handleClickNext.bind(this);
		this.viewRepo = this.viewRepo.bind(this);
	}

	handleClickPrev() {
		console.log(this.props.numImg);
		let nextImageNum = nextImage("prev", this.props.repoName, this.state.currImageNum ,this.props.maxImgs);
		this.setState({currImageNum: nextImageNum});
	}

	handleClickNext() {
		let nextImageNum = nextImage("next", this.props.repoName, this.state.currImageNum, this.props.maxImgs);
		this.setState({currImageNum: nextImageNum});
	}

	viewRepo(){
		firebase.analytics().logEvent("repo_"+ this.props.repoName);
	}

	render() {
		const firstImage = require("./images/Projects/"+this.props.repoName+"/1.jpg");
		let repoLink = ""
		if(this.props.repoUser == null)
			repoLink = "https://github.com/Brenocq/"+this.props.repoName;
		else
			repoLink = "https://github.com/"+this.props.repoUser+"/"+this.props.repoName;
			
		let googlePlayLink = ""
		if(this.props.googlePlay)
			googlePlayLink = "https://play.google.com/store/apps/details?id=com.brenocq."+this.props.googlePlay+"&hl=en"

		const topics = this.props.topics;

		const listTopics = [];

		for (const [index, value] of topics.entries()) {
			if(index%4==0)
				listTopics.push(<br/>);
			listTopics.push(<span className="greyTag">&nbsp; {value} &nbsp;</span> );
		}

		const prevButton = [];
		const nextButton = [];
		const tagHeader = [];

		if(this.props.maxImgs==1){
			prevButton.push(<span></span>);
			nextButton.push(<span></span>);
		}else{
			prevButton.push(<span className="icon-prev" onClick={this.handleClickPrev}/>);
			nextButton.push(<span className="icon-next" onClick={this.handleClickNext}/>);
		}
		if(this.props.state=="Open"){
			tagHeader.push(<span class="projectTagOpen">Open</span>)
		}else{
			tagHeader.push(<span class="projectTagClosed">Closed</span>)
		}

		let featIcon = [];
		if(this.props.googlePlay)
		{
			featIcon.push(
					<a href={googlePlayLink} onClick={this.viewRepo} target="_blank">
						<span class="icon icon-googlePlay"></span>
					</a>)
		}
		else if(this.props.hideRepo == null)
		{
			featIcon.push(
					<a href={repoLink} onClick={this.viewRepo} target="_blank">
						<span class="icon icon-github"></span>
					</a>)
		}

		return (
			<div className="projectWindow">
				<div className="projectHeader">
					{tagHeader}
					<h3>{this.props.title}</h3>
					<span class="projectStart">{this.props.start}</span>
				</div>
				<div className="imageArea">
					{prevButton}
					<img id={this.props.repoName} className="projectImage" src={firstImage} />
					{nextButton}
				</div>

				<p>
					{this.props.description}
				</p>

				<div className="featProject">
					<div className="topicsContainer">
						{listTopics}
					</div>
						{featIcon}
				</div>
			</div>
		);
	}
}

export default ProjectWindow;
