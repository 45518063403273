import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Link, BrowserRouter as Router, Switch } from 'react-router-dom';
import Main from './pages/Main';
import Projects from './pages/Projects';
import Tutorials from './pages/Tutorials';
import NotFound from './pages/NotFound';

const routing = (
  <Router>
    <div>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route path="/projects" component={Projects} />
        <Route path="/tutorials" component={Tutorials} />
        <Route component={NotFound} />
      </Switch>
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById('root'));
