import React from 'react';
import ReactDOM from 'react-dom';
import ProjectWindow from '../ProjectWindow';
import Header from '../Header'
import Footer from '../Footer'
import './Main.css';
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';

class ProjectsContainer extends React.Component {

  render() {
    const { t } = this.props;
    return (
      <div>
      <div className="projectsContainer">
      <ProjectWindow
        title={t('projects.Atta.title')}
        state="Open"
        start="jul 2020"
        repoName="Atta"
        description={t('projects.Atta.description')}
        topics={["Robotics"]}
        maxImgs="4"
	  />
      <ProjectWindow
        title={t('projects.SongTron.title')}
        state="Closed"
        start="jul 2020"
        repoName="songtron"
		repoUser="henriquenunez"
        description={t('projects.SongTron.description')}
        topics={["Image Processing", "Music"]}
        maxImgs="3"
	  />
      <ProjectWindow
        title={t('projects.arqProcICMC.title')}
        state="Closed"
        start="Jun 2020"
        repoName="arquiteturaProcessador-ICMC"
		repoUser="madukm"
        description={t('projects.arqProcICMC.description')}
        topics={["Computer architecture", "Curses", "OpenGL"]}
        maxImgs="3"
	  />
      <ProjectWindow
        title={t('projects.Pacman.title')}
        state="Closed"
        start="May 2020"
        repoName="pacman-with-graphs"
		repoUser="henriquenunez"
        description={t('projects.Pacman.description')}
        topics={["Curses", "Game"]}
        maxImgs="2"
	  />
      <ProjectWindow
        title={t('projects.HoneybeeSimulation.title')}
        state="Closed"
        start="Apr 2020"
        repoName="Honeybee-Simulation"
        description={t('projects.HoneybeeSimulation.description')}
        topics={["Swarm", "OpenGL", "Cuda"]}
        maxImgs="4"
	  />
      <ProjectWindow
        title={t('projects.MyMachine.title')}
        state="Closed"
        start="Mar 2020"
        repoName="MyMachine"
        description={t('projects.MyMachine.description')}
        topics={["Computer architecture","Curses"]}
        maxImgs="3"
      />
      <ProjectWindow
        title={t('projects.ThreeWheeledEducationalRobot.title')}
        state="Closed"
        start="Mar 2020"
        repoName="Three-Wheeled-Educational-Robot"
        description={t('projects.ThreeWheeledEducationalRobot.description')}
        topics={["Robotics","Teaching"]}
        maxImgs="4"
      />
      <ProjectWindow
        title={t('projects.Improved.title')}
        state="Open"
        start="Fev 2020"
		repoName="Improved"
		googlePlay="improved"
        description={t('projects.Improved.description')}
        topics={["Android", "Productivity"]}
        maxImgs="5"
      />
      <ProjectWindow
        title={t('projects.OrgChartJS.title')}
        state="Closed"
        start="Feb 2020"
        repoName="OrgChartJS"
        description={t('projects.OrgChartJS.description')}
        topics={["Web","Javascript"]}
        maxImgs="1"
      />
      <ProjectWindow
        title={t('projects.Cryptobot.title')}
        state="Closed"
        start="Jan 2020"
        repoName="Cryptobot"
		hideRepo="true"
        description={t('projects.Cryptobot.description')}
        topics={["Investment", "TelegramBot", "Flutter", "Database"]}
        maxImgs="1"
      />
      <ProjectWindow
        title={t('projects.Checkers.title')}
        state="Closed"
        start="Dez 2019"
        repoName="Checkers"
        description={t('projects.Checkers.description')}
        topics={["OpenGL","Game"]}
        maxImgs="3"
      />
      <ProjectWindow
        title={t('projects.RoboticArmControl.title')}
        state="Closed"
        start="Nov 2019"
        repoName="N-AxisRoboticArmControl"
        description={t('projects.RoboticArmControl.description')}
        topics={["Robotics","Genetic Alg","Unity"]}
        maxImgs="2"
      />
      <ProjectWindow
        title={t('projects.Telescope.title')}
        state="Closed"
        start="Oct 2019"
        repoName="Telescope"
        description={t('projects.Telescope.description')}
        topics={["SolidWorks","Arduino"]}
        maxImgs="5"
      />
      <ProjectWindow
        title={t('projects.ClassyConvexity.title')}
        state="Closed"
        start="Oct 2019"
        repoName="ClassyConvexity"
        description={t('projects.ClassyConvexity.description')}
        topics={["Machine Learning","Comp. Geometry"]}
        maxImgs="3"
      />
      <ProjectWindow
        title={t('projects.ComputationalGeometry.title')}
        state="Closed"
        start="Aug 2019"
        repoName="ComputationalGeometryOpenGL"
        description={t('projects.ComputationalGeometry.description')}
        topics={["Comp. Geometry","OpenGL"]}
        maxImgs="4"
      />
      <ProjectWindow
        title={t('projects.GuessNumber.title')}
        state="Closed"
        start="Jul 2019"
        repoName="GuessNumber-NeuralNetwork"
        description={t('projects.GuessNumber.description')}
        topics={["Neural Network","Unity"]}
        maxImgs="13"
      />
      <ProjectWindow
        title={t('projects.WarthogHolidayWork.title')}
        state="Closed"
        start="Jul 2019"
        repoName="WarthogHolidayWork"
        description={t('projects.WarthogHolidayWork.description')}
        topics={["Pathfinding","QT"]}
        maxImgs="5"
      />
      <ProjectWindow
        title={t('projects.EvolutiveSystemOpenGL.title')}
        state="Closed"
        start="Jul 2019"
        repoName="EvolutiveSystemOpenGL-ObstacleAvoidance"
        description={t('projects.EvolutiveSystemOpenGL.description')}
        topics={["Genetic Alg","OpenGL","Robotics"]}
        maxImgs="1"
      />
      <ProjectWindow
        title={t('projects.2DPenter.title')}
        state="Closed"
        start="Jun 2019"
        repoName="2DPenter"
        description={t('projects.2DPenter.description')}
        topics={["Robotics","IOT","Arduino"]}
        maxImgs="15"
      />
      <ProjectWindow
        title={t('projects.EvolutiveSystemAnalyzer.title')}
        state="Closed"
        start="May 2019"
        repoName="EvolutiveSystemAnalyzer-ObstacleAvoidance"
        description={t('projects.EvolutiveSystemAnalyzer.description')}
        topics={["Genetic Alg","ROS","MATLAB","Robotics"]}
        maxImgs="13"
      />
      <ProjectWindow
        title={t('projects.SensorDataFirebase.title')}
        state="Closed"
        start="Ago 2018"
        repoName="SensorDataFirebase"
        description={t('projects.SensorDataFirebase.description')}
        topics={["Firebase","IOT","Arduino"]}
        maxImgs="1"
      />
      <ProjectWindow
        title={t('projects.SoccerOpenRCJ.title')}
        state="Closed"
        start="Dec 2017"
        repoName="SoccerOpenRCJ"
        description={t('projects.SoccerOpenRCJ.description')}
        topics={["Robotics","V-REP","Altium","SolidWorks","Arduino"]}
        maxImgs="20"
      />
      <ProjectWindow
        title={t('projects.3DPrinter.title')}
        state="Closed"
        start="Jun 2017"
        repoName="Parallelepiped-3DPrinter"
        description={t('projects.3DPrinter.description')}
        topics={["Robotics","Arduino"]}
        maxImgs="3"
      />
      </div>
      </div>
    );
  }
}

class TProjectsContainer extends translate(ProjectsContainer){}

class Projects extends React.Component {
  render() {
    return (
      <div>
      <Header page="Projects"/>
      <TProjectsContainer/>
      <Footer/>
      </div>
    );
  }
}

export default Projects;
