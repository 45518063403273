import React from 'react';
import ReactDOM from 'react-dom';
import TutorialWindow from '../TutorialWindow';
import Header from '../Header'
import Footer from '../Footer'
import './Tutorials.css';
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';


class TutorialContainer extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="tutorialContainer">
          <TutorialWindow
              title={t('tutorials.OpenGLTutorial.title')}
              repoName="OpenGL-Tutorial"
              description={t('tutorials.OpenGLTutorial.description')}
              topics={["OpenGL","C++"]}
               />
        </div>
      </div>
    );
  }
}

class TTutorialContainer extends translate(TutorialContainer){}

class Tutorials extends React.Component {
  render() {
    return (
      <div>
        <Header page="Tutorials"/>
        <TTutorialContainer/>
      </div>
    );
  }
}

export default Tutorials;
