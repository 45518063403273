import React from 'react';
import ReactDOM from 'react-dom';
import ProjectWindow from '../ProjectWindow';
import Header from '../Header'
import './Main.css';

class NotFound extends React.Component {
  render() {
    return (
      <div>
        <Header page="NotFound"/>
        <div className="title">
          THIS PAGE WAS NOT FOUND...
        </div>
      </div>
    );
  }
}

export default NotFound;
