import firebase from 'firebase';
                               
const config = {              
  apiKey: "AIzaSyBdEtJqZJ69o_Y-UoUsAWQHpG2PxJPx2r4",
  authDomain: "mywebsite-e2b5c.firebaseapp.com",
  databaseURL: "https://mywebsite-e2b5c.firebaseio.com",
  projectId: "mywebsite-e2b5c",
  storageBucket: "mywebsite-e2b5c.appspot.com",
  messagingSenderId: "794568356427",
  appId: "1:794568356427:web:9b151be5b00d93ce31d3ce",
  measurementId: "G-G1QNDZL84K"
};

firebase.initializeApp(config);
export default firebase; 
