import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import ProjectWindow from '../ProjectWindow';
import Header from '../Header';
import Footer from '../Footer';
import './Main.css';
import firebase from '../config/firebaseInit';
import myFace from '../images/BrenoQueiroz.jpeg'

import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';
import PropTypes from 'prop-types';


class StartBody extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="startBodyIndex">
        <div>
            <span id="name">Breno Cunha Queiroz<br/></span>
            <span id="nameDescription">{t('main.startBody.description')}<br/></span><br/>
            <span id="longDescription">{t('main.aboutMe')}<br/></span><br/>
        </div>
        <div class="myImage">
            <img src={myFace} alt=""/>
        </div>
      </div>
    );
  }
}

class Models3D extends React.Component {
  render() {
    return (
      <div className="models3D">
        <div className="sketchfab-embed-wrapper">
            <iframe title="Soccer Robot" width="100%" height="100%" src="https://sketchfab.com/models/8c959e43b6e247029321edcf4d55705d/embed?autospin=0.2&amp;autostart=1"
            frameborder="0" allow="autoplay; fullscreen; vr" mozallowfullscreen="true" webkitallowfullscreen="true"/>
        </div>
        <div className="sketchfab-embed-wrapper">
            <iframe title="2D penter" width="100%" height="100%" src="https://sketchfab.com/models/baa433d75d144eb6b934082e33339f01/embed?autospin=0.2&amp;autostart=1"
            frameborder="0" allow="autoplay; fullscreen; vr" mozallowfullscreen="true" webkitallowfullscreen="true"/>
        </div>
      </div>
    );
  }
}

class AboutMe extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <span class="title"></span><br/>
      </div>
    );
  }
}

class Skills extends React.Component {
  render() {
    const { t } = this.props;
    const knowledge = ['Robotics', 'Computer Vision', 'Computer Graphics', '3D modeling', 'Electronic Circuit Design', '3D printing', 'Android application', 'Website development', 'Database/API'];
    const tools = ['Esp-idf', 'Vulkan API', 'OpenGL', 'Fusion 360', 'Altium', 'React', 'Flutter', 'Django', 'PostgreSQL'];
    const robotics = ['SLAM', 'Multi-robot systems', 'Esp32 Chip', '2D/3D simulation' ];
    const listKnowledge = knowledge.map((knowledge) =>
                            <li key={knowledge.index}>{knowledge}</li>
                          );
    const listTools = tools.map((tools) =>
                            <li key={tools.index}>{tools}</li>
                          );
    const listRobotics = robotics.map((robotics) =>
                            <li key={robotics.index}>{robotics}</li>
                          );
    return (
      <div className="skills">
          <span className="title">{t('main.skills.title')}</span><br/>
          <div className="cols3">
              <div>
                  <span className="subTitle">{t('main.skills.knowledgeTitle')}</span><br/>
                  <ul>{listKnowledge}</ul>
              </div>

              <div>
                  <span className="subTitle">{t('main.skills.toolsTitle')}</span><br/>
                  <ul>{listTools}</ul>
              </div>

              <div>
                  <span className="subTitle">{t('main.skills.roboticsTitle')}</span><br/>
                  <ul>{listRobotics}</ul>
              </div>
          </div>
      </div>
    );
  }
}

class Paper extends React.Component {
  constructor(props) {
    super(props);

    this.viewPaper = this.viewPaper.bind(this);
  }

  viewPaper(){
    firebase.analytics().logEvent("paper_"+ this.props.event);
  }

  render() {
    const topics = this.props.topics;
    const listTopics = [];

    for (const [index, value] of topics.entries()) {
      listTopics.push(<span className="greyTag">&nbsp; {value} &nbsp;</span>);
    }
    return(
      <div className="publicationWindow">
          <div className="publicationPlace">
              {this.props.place}
              <br/>
              {this.props.year}
          </div>
          <div>
              <div className="publicationTitle">
                {this.props.title}
              </div>
              {this.props.authors}

              <div className="publicationFeet">
                {listTopics}
              </div>
          </div>
          <div className="publicationLink">
              <a href={this.props.link} onClick={this.viewPaper} target="_blank">
                  <span className="icon icon-link"></span>
              </a>
          </div>
      </div>
    );
  }
}

class Publications extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div class="publications">
        <span class="title">{t('main.publications.title')}</span><br/>
        <div class="adjustBodyIndex">
          <div className="publicationsContainer">

            <Paper
            title={t('main.publications.febrace.title')}
            authors="Breno Cunha Queiroz, Rafael Bastos Duarte, Fabio Ferreira"
            place={t('main.publications.febrace.place')}
            year="2019"
            event="febrace"
            topics={["Automation","Greenhouse","Education"]}
            link="https://febrace.org.br/arquivos/site/_conteudo/pdf/anais2019.pdf#page=96"
            />

            <Paper
            title={t('main.publications.soccerMNR2018.title')}
            authors="Breno Cunha Queiroz, Rafael Bastos Duarte, Fabio Ferreira, Ivisson Valverde"
            place={t('main.publications.soccerMNR2018.place')}
            year="2018"
            event="soccer_MNR_2018"
            topics={["RobocupJunior","Soccer Robots","Simulation"]}
            link="http://sistemaolimpo.org/midias/uploads/ca9cc8804d80946587048f57d4bce7a2.pdf"
            />

            <Paper
            title={t('main.publications.soccerMNR2017.title')}
            authors="Breno Cunha Queiroz, Fabio Ferreira, Ivisson Valverde"
            place={t('main.publications.soccerMNR2017.place')}
            year="2017"
            event="soccer_MNR_2017"
            topics={["Robot","Autonomous","RoboCupJunior"]}
            link="http://www.mnr.org.br/wp-content/uploads/2019/06/MNR-Anais2017.pdf#page=303"
            />


          </div>
        </div>
      </div>
    );
  }
}

class SomeProjects extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="someProjects">
          <span className="title">{t('main.someProjects.title')}</span><br/>
          <div className="adjustBodyIndex">
            <div className="projectsContainer">
			  <ProjectWindow
				title={t('projects.Atta.title')}
				state="Open"
				start="jul 2020"
				repoName="Atta"
				description={t('projects.Atta.description')}
				topics={["Robotics"]}
				maxImgs="4"
			  />
              <ProjectWindow
                title={t('projects.SoccerOpenRCJ.title')}
                state="Closed"
                start="Dec 2017"
                repoName="SoccerOpenRCJ"
                description={t('projects.SoccerOpenRCJ.description')}
                topics={["Robotics","V-REP","Altium","SolidWorks","Arduino"]}
                maxImgs="20"
              />
             <ProjectWindow
               title={t('projects.3DPrinter.title')}
               state="Closed"
               start="Jun 2017"
               repoName="Parallelepiped-3DPrinter"
               description={t('projects.3DPrinter.description')}
               topics={["Robotics","Arduino"]}
               maxImgs="3"
              />
            </div>
          </div>
          <br/>
          <Link to="/Projects" className="buttonProjects"> &nbsp; {t('main.someProjects.seeMore')} &nbsp; </Link>
        </div>
      </div>
    );
  }
}

class TStartBody extends translate(StartBody){}
class TAboutMe extends translate(AboutMe){}
class TSkills extends translate(Skills){}
class TPublications extends translate(Publications){}
class TSomeProjects extends translate(SomeProjects){}

class Main extends React.Component {
  render() {
    return (
      <div>
      <Header page="Home"/>
        <div className="bodyIndex">
          <TStartBody/>
          <Models3D/>
          <TAboutMe/>
          <TSkills/>
          <TPublications/>
          <TSomeProjects/>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default translate(Main);
