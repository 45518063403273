import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import './Header.css';

import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';
import PropTypes from 'prop-types';

import en from './lang/en.json';
import pt from './lang/pt.json';
setTranslations({ en, pt });
setDefaultLanguage('en');
setLanguageCookie();

//---------- Dropdown language menu ----------//

class SelectLanguage extends Component{
  constructor() {
    super();

    this.state = {
      showMenu: false,
    }
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.closeMenu);
      });
  }

  handleSetLanguage = (key) => () => {
    setLanguage(key);
  };

  render() {
    const { t } = this.props;
      return (
        <div className="lang">
          <div onClick={this.showMenu}>
          {
            this.state.showMenu ? (null) : (
              t('header.lang.title')
            )
          }
          </div>
          {
           this.state.showMenu ? (
             <div>
                 <div className="dropdownButton" onClick={this.handleSetLanguage('en')}> {t('header.lang.en')} </div>
                 <div className="dropdownButton" onClick={this.handleSetLanguage('pt')}> {t('header.lang.pt')} </div>
               </div>
            ):(null)
          }
        </div>
      );
    }
}

//---------- Header ----------//
class TSelectLanguage extends translate(SelectLanguage){}

class Header extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="header">
        <div className="menuName">BCQ</div>
        <div className="nav">
            <ul>
              <li>
                <NavLink exact activeClassName="current" to="/">
                  <span>-</span> {t('header.home')}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="current" to="/projects">
                  <span>-</span> {t('header.projects')}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="current" to="/tutorials">
                  <span>-</span> {t('header.tutorials')}
                </NavLink>
              </li>
            </ul>
        </div>

        <div className="menuButtonMobile">
          <i className="fa fa-bars"/>
        </div>
        <TSelectLanguage/>
        <div className="navMobile">
          <ul>
            <li>
              <NavLink exact activeClassName="current" to="/">
                <span>-</span> {t('header.home')}
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="current" to="/projects">
                <span>-</span> {t('header.projects')}
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="current" to="/tutorials">
                <span>-</span> {t('header.tutorials')}
              </NavLink>
            </li>
          </ul>
        </div>

      </div>
    );
  }
}

export default translate(Header);
